import * as Dialog from '@radix-ui/react-dialog';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { hideModal } from 'shared/foreground/actions/modals';

import useDevOnlyHtmlIdValidation from '../../hooks/useDevOnlyHtmlIdValidation';
import useIsModalShown from '../../utils/useIsModalShown';
import Button from '../Button';
import CloseIcon from '../icons/CloseIcon';
import styles from './Modal.module.css';

export default function Modal({
  footerContent,
  id,
  mainContent,
  title,
  rootClassName,
  mainContentClassName,
  showHeader = true,
}: {
  footerContent?: React.ReactNode | string;
  id: string;
  mainContent: React.ReactNode | string;
  title: string;
  rootClassName?: string;
  mainContentClassName?: string;
  showHeader?: boolean;
}) {
  useDevOnlyHtmlIdValidation(id);
  const isShown = useIsModalShown(id);

  const [doesContainerExist, setDoesContainerExist] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const setContainerRef = useCallback((refValue: HTMLDivElement | null) => {
    containerRef.current = refValue;
    setDoesContainerExist(Boolean(refValue));
  }, []);
  useEffect(() => () => setDoesContainerExist(false), []);

  if (!isShown) {
    return null;
  }

  let footer: JSX.Element | null = null;
  if (footerContent) {
    footer = <footer className={styles.modalFooter}>{footerContent}</footer>;
  }
  const rootClasses = [styles.modalRoot];
  if (rootClassName) {
    rootClasses.push(rootClassName);
  }

  const mainContentClasses = [styles.modalMain];
  if (mainContentClassName) {
    mainContentClasses.push(mainContentClassName);
  }
  return (
    <>
      <div ref={setContainerRef} />
      <Dialog.Root open={doesContainerExist}>
        <Dialog.Portal container={containerRef.current}>
          <Dialog.Overlay
            className={styles.modalBackdrop}
            onClick={() => hideModal({ id }, { userInteraction: 'click' })}
          />
          <Dialog.Content
            className={rootClasses.join(' ')}
            onKeyDown={(event) => {
              event.stopPropagation();
              if (event.code === 'Escape') {
                hideModal({ id }, { userInteraction: 'keydown' });
              }
            }}
            onKeyUp={(event) => event.stopPropagation()}
            id={id}
          >
            {showHeader &&
              <header className={styles.modalHeader}>
                <Dialog.Title className={styles.title}>{title}</Dialog.Title>
              <Dialog.Close asChild>
                <Button
                  className={styles.closeButton}
                  onClick={() => hideModal({ id }, { userInteraction: 'click' })}
                  variant="unstyled"
                >
                  <CloseIcon />
                </Button>
                </Dialog.Close>
              </header>
            }

            <main className={mainContentClasses.join(' ')}>{mainContent}</main>

            {footer}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
}
